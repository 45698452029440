import gql from 'graphql-tag';
import * as Urql from '@urql/vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | Ref<T> | ComputedRef<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export enum AdDisplayAt {
  BlogDetailBottom = 'BLOG_DETAIL_BOTTOM',
  BlogDetailTop = 'BLOG_DETAIL_TOP',
  Brand = 'BRAND',
  BrochureAbove = 'BROCHURE_ABOVE',
  BrochureEndAboveGoogleMaps = 'BROCHURE_END_ABOVE_GOOGLE_MAPS',
  BrochureEndInteresting = 'BROCHURE_END_INTERESTING',
  BrochureEndLeft = 'BROCHURE_END_LEFT',
  BrochureEndRight = 'BROCHURE_END_RIGHT',
  BrochureStart = 'BROCHURE_START',
  BrochureStartBottom = 'BROCHURE_START_BOTTOM',
  BrochureViewerInterface = 'BROCHURE_VIEWER_INTERFACE',
  Categories = 'CATEGORIES',
  Category = 'CATEGORY',
  FavoritesEnd = 'FAVORITES_END',
  HomepageBottom = 'HOMEPAGE_BOTTOM',
  HomepageTop = 'HOMEPAGE_TOP',
  NewBrochures = 'NEW_BROCHURES',
  NewBrochuresBottom = 'NEW_BROCHURES_BOTTOM',
  NewBrochuresWeb = 'NEW_BROCHURES_WEB',
  Shop = 'SHOP'
}

export enum AdPlatformType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export enum AdTargetType {
  Default = 'DEFAULT',
  Fallback = 'FALLBACK'
}

export enum AdType {
  HtmlEmbed = 'HTML_EMBED',
  ImageAd = 'IMAGE_AD'
}

export type AdsAdvertisementEntity = {
  /** @deprecated Remove from any query. Type is already resolved by interface implementations. */
  adType: AdType;
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  displayAt: AdDisplayAt;
  /** @deprecated Use displayUrl on AdsAdvertismentImage instead */
  displayUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Remove from any query. */
  platformType: AdPlatformType;
  shops?: Maybe<Array<Maybe<ShopEntity>>>;
  targetType: AdTargetType;
  weight: Scalars['Int']['output'];
};

export type AdsAdvertisementHtmlEmbed = AdsAdvertisementEntity & {
  /** @deprecated Remove from any query. Type is already resolved by interface implementations. */
  adType: AdType;
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  content: Scalars['String']['output'];
  displayAt: AdDisplayAt;
  /** @deprecated HtmlEmbed ads will no longer have urls. Remove field from any query. */
  displayUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Remove from any query. */
  platformType: AdPlatformType;
  shops?: Maybe<Array<Maybe<ShopEntity>>>;
  targetType: AdTargetType;
  weight: Scalars['Int']['output'];
};

export type AdsAdvertisementImage = AdsAdvertisementEntity & {
  /** @deprecated Remove from any query. Type is already resolved by interface implementations. */
  adType: AdType;
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  displayAt: AdDisplayAt;
  displayUrl: Scalars['String']['output'];
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Remove from any query. */
  platformType: AdPlatformType;
  shops?: Maybe<Array<Maybe<ShopEntity>>>;
  targetType: AdTargetType;
  weight: Scalars['Int']['output'];
};

export type BrandEntity = {
  description?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type BrandEntityFileUrlArgs = {
  version: BrandFileVersion;
};

export enum BrandFileVersion {
  Small = 'SMALL'
}

export type BrandInput = {
  slug: Scalars['String']['input'];
};

export type BrandsInput = {
  searchFilter?: InputMaybe<SearchFilter>;
};

export type BrochureEntity = {
  activeFrom: Scalars['DateTime']['output'];
  cover: PageEntity;
  expireAfter: Scalars['DateTime']['output'];
  hotspotMode: HotspotMode;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  publishedAt: Scalars['DateTime']['output'];
  regional: Scalars['Boolean']['output'];
  shareUrl: Scalars['String']['output'];
  shop: ShopEntity;
};


export type BrochureEntityCoverArgs = {
  point?: InputMaybe<Point>;
};

export enum BrochureImpressionSource {
  /** When a brochure is seen in an 'also-intersting'/'suggested' area/panel. */
  AlsoInteresting = 'ALSO_INTERESTING',
  /** When a brochure is seen on the brand's detail page. */
  Brand = 'BRAND',
  /** When a brochure on a specific category page is seen. */
  Category = 'CATEGORY',
  /** When a brochure is seen on the city's detail page. */
  City = 'CITY',
  /** When a brochure is seen on the favorites page. */
  Favorites = 'FAVORITES',
  /** When a brochure is seen on the 'new' overview page. */
  NewOpen = 'NEW_OPEN',
  /** When a brochure is seen after using a user query. */
  Search = 'SEARCH',
  /** When a brochure is seen on the shop's detail page. */
  Shop = 'SHOP',
  /** When a brochure is seen on the main category overview. */
  Stack = 'STACK'
}

export type BrochureInput = {
  id: Scalars['ID']['input'];
};

export type BrochureSearchEntity = {
  activeFrom: Scalars['DateTime']['output'];
  brochureRegionId: Scalars['ID']['output'];
  cover: PageEntity;
  expireAfter: Scalars['DateTime']['output'];
  hotspotMode: HotspotMode;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  publishedAt: Scalars['DateTime']['output'];
  regional: Scalars['Boolean']['output'];
  shareUrl: Scalars['String']['output'];
  shop: ShopEntity;
};


export type BrochureSearchEntityCoverArgs = {
  point?: InputMaybe<Point>;
};

export enum BrochureVisitSource {
  /** When a brochure was opened from an 'also-interesting'/'suggested' area/panel. */
  AlsoInteresting = 'ALSO_INTERESTING',
  /** When a brochure was opened from an app-link. */
  AppLink = 'APP_LINK',
  /** When a brochure is visited from the brand's detail page. */
  Brand = 'BRAND',
  /** When a brochure was opened from a specific category page. */
  Category = 'CATEGORY',
  /** When a brochure is visited from the city's detail page. */
  City = 'CITY',
  /** When a brochure is visited via an embedded brochure viewer. */
  Embed = 'EMBED',
  /** When a brochure was opened from the favorites. */
  Favorites = 'FAVORITES',
  /** When a brochure was opened from the new overview page. */
  NewOpen = 'NEW_OPEN',
  /** When a brochure is opened from the background of the app with the brochure open. */
  ReopenBackground = 'REOPEN_BACKGROUND',
  /** When a brochure was opened based on a search query or from a search results page. */
  Search = 'SEARCH',
  /** When a brochure is visited from the shop's detail page. */
  Shop = 'SHOP',
  /** When a brochure has unknown source. Currently only used by web 2.0. */
  Unknown = 'UNKNOWN'
}

export type BrochuresInput = {
  brandSlug?: InputMaybe<Scalars['String']['input']>;
  categorySlug?: InputMaybe<Scalars['String']['input']>;
  citySlug?: InputMaybe<Scalars['String']['input']>;
  point?: InputMaybe<Point>;
  shopIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  shopSlug?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryCoverEntity = {
  brochureId: Scalars['ID']['output'];
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageRatio: Scalars['Float']['output'];
};


export type CategoryCoverEntityFileUrlArgs = {
  version: CategoryCoverFileVersion;
};

export enum CategoryCoverFileVersion {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type CategoryEntity = {
  about?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<CategoryCoverEntity>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type CategoryEntityFileUrlArgs = {
  version: CategoryFileVersion;
};

export enum CategoryFileVersion {
  Small = 'SMALL'
}

export type CategoryInput = {
  id: Scalars['ID']['input'];
};

export type CityEntity = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type CityInput = {
  slug: Scalars['String']['input'];
};

export type ConsentInput = {
  granted: Scalars['Boolean']['input'];
  service: GdprConsentService;
};

export type CreateAdClickStatisticInput = {
  adsAdvertisementId: Scalars['ID']['input'];
  deviceId: Scalars['ID']['input'];
};

export type CreateAdImpressionStatisticInput = {
  adsAdvertisementId: Scalars['ID']['input'];
  deviceId: Scalars['ID']['input'];
};

export type CreateBrochureImpressionStatisticInput = {
  brochureId: Scalars['ID']['input'];
  deviceId: Scalars['ID']['input'];
  source: BrochureImpressionSource;
};

export type CreateBrochureVisitStatisticInput = {
  brochureId: Scalars['ID']['input'];
  deviceId: Scalars['ID']['input'];
  location?: InputMaybe<Point>;
  source: BrochureVisitSource;
};

export type CreateDeviceInput = {
  /** Push token */
  token?: InputMaybe<Scalars['String']['input']>;
  type: DeviceType;
  /** identifier generated by the client */
  uid: Scalars['ID']['input'];
};

export enum CreateDeviceStatus {
  Ok = 'OK'
}

export enum CreateGdprConsentStatus {
  DeviceNotFound = 'DEVICE_NOT_FOUND',
  Ok = 'OK'
}

export type CreateGdprConsentsInput = {
  consents: Array<ConsentInput>;
  deviceId: Scalars['ID']['input'];
};

export type CreateHotspotStatisticInput = {
  deviceId: Scalars['ID']['input'];
  hotspotId: Scalars['ID']['input'];
  secondsSpent?: InputMaybe<Scalars['Float']['input']>;
  statisticsType: HotspotStatisticsType;
};

export type CreatePageTimeStatisticInput = {
  deviceId: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
  timeInSeconds: Scalars['Float']['input'];
};

export type CreatePageVisitStatisticInput = {
  deviceId: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
};

export type CreateStatistic = {
  status?: Maybe<MutatedStatus>;
};

export enum DeviceType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export enum GdprConsentService {
  Accurat = 'ACCURAT',
  Appsflyer = 'APPSFLYER'
}

export type HotspotEntity = {
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  pageId: Scalars['ID']['output'];
  shareUrl: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export enum HotspotFileVersion {
  Original = 'ORIGINAL',
  Small = 'SMALL'
}

export type HotspotImageEntity = HotspotEntity & {
  fileUrl?: Maybe<Scalars['String']['output']>;
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  pageId: Scalars['ID']['output'];
  shareUrl: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};


export type HotspotImageEntityFileUrlArgs = {
  version: HotspotFileVersion;
};

export type HotspotLinkEntity = HotspotEntity & {
  fileUrl?: Maybe<Scalars['String']['output']>;
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  pageId: Scalars['ID']['output'];
  shareUrl: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};


export type HotspotLinkEntityFileUrlArgs = {
  version: HotspotFileVersion;
};

export enum HotspotMode {
  Disabled = 'DISABLED',
  Links = 'LINKS',
  ProductsWithoutLink = 'PRODUCTS_WITHOUT_LINK',
  ProductsWithLink = 'PRODUCTS_WITH_LINK'
}

export type HotspotProductEntity = HotspotEntity & {
  fileUrl?: Maybe<Scalars['String']['output']>;
  height: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  pageId: Scalars['ID']['output'];
  productTag: OfferEntity;
  shareUrl: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};


export type HotspotProductEntityFileUrlArgs = {
  version: HotspotFileVersion;
};

export enum HotspotStatisticsType {
  /** When a product is 'added to list'. */
  AddedToList = 'ADDED_TO_LIST',
  /** When a hotspot with type 'link' is clicked/tapped. */
  LinkClick = 'LINK_CLICK',
  /** When a hotspot with type 'product' is clicked/tapped. */
  ProductClick = 'PRODUCT_CLICK',
  /** When the url from a product tag is clicked/tapped. */
  ProductLinkClick = 'PRODUCT_LINK_CLICK',
  /** What total time was spent showing a product tag. */
  ProductTimeSpent = 'PRODUCT_TIME_SPENT'
}

export type HotspotsByPageInput = {
  ignoreHotspotMode?: InputMaybe<Scalars['Boolean']['input']>;
  pageId: Scalars['ID']['input'];
};

export type HotspotsInput = {
  brochureRegionId: Scalars['ID']['input'];
};

export type IdentityUserEntity = {
  bio?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};


export type IdentityUserEntityFileUrlArgs = {
  version: IdentityUserFileVersion;
};

export enum IdentityUserFileVersion {
  Small = 'SMALL'
}

export type LocationEntity = {
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  point: LocationPoint;
  shop: ShopEntity;
  zipCode: Scalars['String']['output'];
};

export type LocationPoint = {
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type LocationWithDistanceEntity = LocationEntity & {
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  distance: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  point: LocationPoint;
  shop: ShopEntity;
  zipCode: Scalars['String']['output'];
};

export type LocationsInput = {
  point: Point;
  shopId: Scalars['ID']['input'];
};

export type MutatedCreateDevice = {
  status?: Maybe<CreateDeviceStatus>;
};

export type MutatedCreateGdprConsent = {
  status?: Maybe<CreateGdprConsentStatus>;
};

export enum MutatedStatus {
  Failed = 'FAILED',
  Ok = 'OK'
}

export type OfferCategoryEntity = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type OfferEntity = {
  activeFrom: Scalars['DateTime']['output'];
  brandName?: Maybe<Scalars['String']['output']>;
  brochureId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discountPercent?: Maybe<Scalars['Float']['output']>;
  expireAfter: Scalars['DateTime']['output'];
  hotspot: HotspotEntity;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pageIndex: Scalars['Int']['output'];
  priceAfterDiscount?: Maybe<Scalars['Float']['output']>;
  priceBeforeDiscount?: Maybe<Scalars['Float']['output']>;
  shop: ShopEntity;
};

export type OfferSearchEntity = {
  activeFrom: Scalars['DateTime']['output'];
  brandName?: Maybe<Scalars['String']['output']>;
  brochureId: Scalars['ID']['output'];
  brochureRegionId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discountPercent?: Maybe<Scalars['Float']['output']>;
  expireAfter: Scalars['DateTime']['output'];
  hotspot: HotspotEntity;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pageIndex: Scalars['Int']['output'];
  priceAfterDiscount?: Maybe<Scalars['Float']['output']>;
  priceBeforeDiscount?: Maybe<Scalars['Float']['output']>;
  regional: Scalars['Boolean']['output'];
  shop: ShopEntity;
};

export type OffersInput = {
  brandSlug?: InputMaybe<Scalars['String']['input']>;
  brochureId?: InputMaybe<Scalars['ID']['input']>;
  citySlug?: InputMaybe<Scalars['String']['input']>;
  offerCategorySlug?: InputMaybe<Scalars['String']['input']>;
  point?: InputMaybe<Point>;
  search?: InputMaybe<Scalars['String']['input']>;
  shopId?: InputMaybe<Scalars['ID']['input']>;
  shopSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PageEntity = {
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageRatio: Scalars['Float']['output'];
  index: Scalars['Int']['output'];
};


export type PageEntityFileUrlArgs = {
  version: PageFileVersion;
};

export enum PageFileVersion {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type PagesInput = {
  brochureId: Scalars['ID']['input'];
  point?: InputMaybe<Point>;
};

export type PaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type Point = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type RootMutationType = {
  /** Create ad click statistic */
  createAdClickStatistic?: Maybe<CreateStatistic>;
  /** Create ad impression statistic */
  createAdImpressionStatistic?: Maybe<CreateStatistic>;
  /** Create brochure impression statistic */
  createBrochureImpressionStatistic?: Maybe<CreateStatistic>;
  /** Create brochure visit statistic */
  createBrochureVisitStatistic?: Maybe<CreateStatistic>;
  /**
   * Create or update device
   * @deprecated Devices are no longer created or updated (for GDPR statistics)
   */
  createDevice: MutatedCreateDevice;
  /**
   * Create GDPR consents
   * @deprecated GDPR consent statistics are no longer used
   */
  createGdprConsents: MutatedCreateGdprConsent;
  /** Create hotspots statistics for clicks on product tags, product_tag links, or link clicks. */
  createHotspotStatistic: CreateStatistic;
  /** Create page time statistic */
  createPageTimeStatistic?: Maybe<CreateStatistic>;
  /** Create page visit statistic */
  createPageVisitStatistic?: Maybe<CreateStatistic>;
};


export type RootMutationTypeCreateAdClickStatisticArgs = {
  createAdClickStatistic: CreateAdClickStatisticInput;
};


export type RootMutationTypeCreateAdImpressionStatisticArgs = {
  createAdImpressionStatistic: CreateAdImpressionStatisticInput;
};


export type RootMutationTypeCreateBrochureImpressionStatisticArgs = {
  createBrochureImpressionStatistic: CreateBrochureImpressionStatisticInput;
};


export type RootMutationTypeCreateBrochureVisitStatisticArgs = {
  createBrochureVisitStatistic: CreateBrochureVisitStatisticInput;
};


export type RootMutationTypeCreateDeviceArgs = {
  createDevice: CreateDeviceInput;
};


export type RootMutationTypeCreateGdprConsentsArgs = {
  createGdprConsents: CreateGdprConsentsInput;
};


export type RootMutationTypeCreateHotspotStatisticArgs = {
  createHotspotStatistic: CreateHotspotStatisticInput;
};


export type RootMutationTypeCreatePageTimeStatisticArgs = {
  createPageTimeStatistic: CreatePageTimeStatisticInput;
};


export type RootMutationTypeCreatePageVisitStatisticArgs = {
  createPageVisitStatistic: CreatePageVisitStatisticInput;
};

export type RootQueryType = {
  /** A list of ads_advertisements. */
  adsAdvertisements: Array<AdsAdvertisementEntity>;
  /** A single brand. */
  brand?: Maybe<BrandEntity>;
  /** A list of brands. */
  brands: Array<BrandEntity>;
  /** Get a brochure */
  brochure?: Maybe<BrochureEntity>;
  /** A list of brochures that can be optionally filtered by category, shop, and/or search. */
  brochures: Array<BrochureEntity>;
  /** A list of categories. */
  categories: Array<CategoryEntity>;
  /** Get a category. */
  category?: Maybe<CategoryEntity>;
  /** A list of cities. */
  cities: Array<CityEntity>;
  /** City by slug. */
  city?: Maybe<CityEntity>;
  /** A list of hotspots. */
  hotspots: Array<HotspotEntity>;
  /** A list of hotspots by page. */
  hotspotsByPage: Array<HotspotEntity>;
  /** A list of locations. */
  locations: Array<LocationEntity>;
  /** A list of offer_categories. */
  offerCategories: Array<OfferCategoryEntity>;
  /** A list of offers. */
  offers: Array<OfferEntity>;
  /** A list of pages. */
  pages: Array<PageEntity>;
  /** Accepts a search suggestion or a user query and returns a list of resulting entities of the same type. */
  searchResults: SearchResult;
  /** Accepts a query and returns search suggestions. */
  searchSuggestions: Array<SearchSuggestionEntity>;
  /** Get a shop. */
  shop?: Maybe<ShopEntity>;
  /** A list of shops. */
  shops: Array<ShopEntity>;
  /** A list of brochures that are from the same shop or in the same category as the input brochure. */
  similarBrochuresForBrochure: Array<BrochureEntity>;
  /** A list of brochures that are in the same category, but do no have the same shop as the input brochure. */
  similarBrochuresForShop: Array<BrochureEntity>;
  /** A list of shops with similar categories as the input shop, excluding the input shop. */
  similarShopsForShop: Array<ShopEntity>;
  /** Get a page. */
  websitePage?: Maybe<WebsitePageEntity>;
  /** A list of website_pages for the footer, ordered by position in the footer */
  websitePages: Array<WebsitePagePage>;
  /** A list of website_pages with type post. */
  websitePosts: Array<WebsitePagePost>;
};


export type RootQueryTypeBrandArgs = {
  brand: BrandInput;
};


export type RootQueryTypeBrandsArgs = {
  brands: BrandsInput;
  pagination: PaginationInput;
};


export type RootQueryTypeBrochureArgs = {
  brochure: BrochureInput;
};


export type RootQueryTypeBrochuresArgs = {
  brochures: BrochuresInput;
  pagination: PaginationInput;
};


export type RootQueryTypeCategoryArgs = {
  category: CategoryInput;
};


export type RootQueryTypeCityArgs = {
  city: CityInput;
};


export type RootQueryTypeHotspotsArgs = {
  hotspots: HotspotsInput;
};


export type RootQueryTypeHotspotsByPageArgs = {
  hotspotsByPage: HotspotsByPageInput;
};


export type RootQueryTypeLocationsArgs = {
  locations: LocationsInput;
};


export type RootQueryTypeOfferCategoriesArgs = {
  pagination: PaginationInput;
};


export type RootQueryTypeOffersArgs = {
  offers: OffersInput;
  pagination: PaginationInput;
};


export type RootQueryTypePagesArgs = {
  pages: PagesInput;
};


export type RootQueryTypeSearchResultsArgs = {
  pagination: PaginationInput;
  searchResults: SearchResultsInput;
};


export type RootQueryTypeSearchSuggestionsArgs = {
  searchSuggestions: SearchSuggestionsInput;
};


export type RootQueryTypeShopArgs = {
  shop: ShopInput;
};


export type RootQueryTypeShopsArgs = {
  pagination: PaginationInput;
  shops: ShopsInput;
};


export type RootQueryTypeSimilarBrochuresForBrochureArgs = {
  similarBrochuresForBrochure: SimilarBrochuresForBrochureInput;
};


export type RootQueryTypeSimilarBrochuresForShopArgs = {
  similarBrochuresForShop: SimilarBrochuresForShopInput;
};


export type RootQueryTypeSimilarShopsForShopArgs = {
  pagination: PaginationInput;
  similarShopsForShop: SimilarShopsForShopInput;
};


export type RootQueryTypeWebsitePageArgs = {
  websitePage: WebsitePageInput;
};


export type RootQueryTypeWebsitePostsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  websitePosts: WebsitePostsInput;
};

export type SearchBrochureList = {
  brochures: Array<BrochureSearchEntity>;
  searchMethod: SearchMethod;
};

export enum SearchFileVersion {
  Small = 'SMALL'
}

export enum SearchFilter {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z'
}

export enum SearchMethod {
  BySuggestion = 'BY_SUGGESTION',
  ExactOfferCategoryName = 'EXACT_OFFER_CATEGORY_NAME',
  ExactShopName = 'EXACT_SHOP_NAME',
  ExactShopNameNoBrochures = 'EXACT_SHOP_NAME_NO_BROCHURES',
  NonExactOfferCategoryName = 'NON_EXACT_OFFER_CATEGORY_NAME',
  NonExactOfferName = 'NON_EXACT_OFFER_NAME',
  NonExactShopKeywords = 'NON_EXACT_SHOP_KEYWORDS',
  NonExactShopName = 'NON_EXACT_SHOP_NAME'
}

export type SearchNoResults = {
  status: SearchNoResultsStatus;
};

export enum SearchNoResultsStatus {
  NoResults = 'NO_RESULTS'
}

export type SearchOfferList = {
  offers: Array<OfferSearchEntity>;
  searchMethod: SearchMethod;
};

export type SearchResult = SearchBrochureList | SearchNoResults | SearchOfferList | SearchShopList;

export type SearchResultsInput = {
  point?: InputMaybe<Point>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchMethod?: InputMaybe<SearchMethod>;
  searchSuggestion?: InputMaybe<SearchSuggestionInput>;
};

export type SearchShopList = {
  searchMethod: SearchMethod;
  shops: Array<ShopEntity>;
};

export type SearchSuggestionEntity = {
  fileUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  referenceId: Scalars['ID']['output'];
  resultCount: Scalars['Int']['output'];
  resultLimit: Scalars['Int']['output'];
  shop?: Maybe<ShopEntity>;
  type: SearchSuggestionType;
};


export type SearchSuggestionEntityFileUrlArgs = {
  version: SearchFileVersion;
};

export type SearchSuggestionInput = {
  referenceId: Scalars['ID']['input'];
  type: SearchSuggestionType;
};

export enum SearchSuggestionType {
  Brand = 'BRAND',
  Offer = 'OFFER',
  OfferCategory = 'OFFER_CATEGORY',
  Shop = 'SHOP'
}

export type SearchSuggestionsInput = {
  point?: InputMaybe<Point>;
  query: Scalars['String']['input'];
};

export type ShopEntity = {
  description?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastPage: ShopLastPage;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  showAds: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};


export type ShopEntityFileUrlArgs = {
  version: ShopFileVersion;
};

export enum ShopFileVersion {
  Small = 'SMALL'
}

export type ShopInput = {
  id: Scalars['ID']['input'];
};

export enum ShopLastPage {
  AlsoInteresting = 'ALSO_INTERESTING',
  ShopLocations = 'SHOP_LOCATIONS'
}

export type ShopsInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categorySlug?: InputMaybe<Scalars['String']['input']>;
  /** The point (lat, long) from where distance will be computed to nearest shops. */
  point?: InputMaybe<Point>;
};

export type SimilarBrochuresForBrochureInput = {
  brochureId: Scalars['ID']['input'];
  point?: InputMaybe<Point>;
};

export type SimilarBrochuresForShopInput = {
  shopSlug: Scalars['String']['input'];
};

export type SimilarShopsForShopInput = {
  shopSlug: Scalars['String']['input'];
};

export type WebsitePageEntity = {
  content?: Maybe<Scalars['String']['output']>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  positionInFooter?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum WebsitePageFileVersion {
  Medium = 'MEDIUM'
}

export type WebsitePageInput = {
  path: Scalars['String']['input'];
};

export type WebsitePagePage = WebsitePageEntity & {
  content?: Maybe<Scalars['String']['output']>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  positionInFooter?: Maybe<Scalars['Int']['output']>;
  shopContent: Array<Scalars['String']['output']>;
  shopQuestionsAnswers: Array<WebsitePageQuestionAnswer>;
  title?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IdentityUserEntity>;
};

export type WebsitePagePost = WebsitePageEntity & {
  categories: Array<CategoryEntity>;
  content: Scalars['String']['output'];
  coverImage: Scalars['String']['output'];
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  positionInFooter?: Maybe<Scalars['Int']['output']>;
  publishedAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  user?: Maybe<IdentityUserEntity>;
};


export type WebsitePagePostCoverImageArgs = {
  version: WebsitePageFileVersion;
};

export type WebsitePageQuestionAnswer = {
  answer: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export enum WebsitePostOrderBy {
  PublishedAtAsc = 'PUBLISHED_AT_ASC',
  PublishedAtDesc = 'PUBLISHED_AT_DESC'
}

export type WebsitePostsInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  categorySlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy: WebsitePostOrderBy;
};

type AdAdvertisement_AdsAdvertisementHtmlEmbed_Fragment = { __typename: 'AdsAdvertisementHtmlEmbed', id: string, displayAt: AdDisplayAt, targetType: AdTargetType, weight: number, content: string, categories?: Array<{ id: string } | null> | null, shops?: Array<{ id: string } | null> | null };

type AdAdvertisement_AdsAdvertisementImage_Fragment = { __typename: 'AdsAdvertisementImage', id: string, displayAt: AdDisplayAt, targetType: AdTargetType, weight: number, fileUrl?: string | null, displayUrl: string, categories?: Array<{ id: string } | null> | null, shops?: Array<{ id: string } | null> | null };

export type AdAdvertisementFragment = AdAdvertisement_AdsAdvertisementHtmlEmbed_Fragment | AdAdvertisement_AdsAdvertisementImage_Fragment;

export type AdAdvertisementHtmlEmbedFragment = { __typename: 'AdsAdvertisementHtmlEmbed', id: string, displayAt: AdDisplayAt, targetType: AdTargetType, weight: number, content: string, categories?: Array<{ id: string } | null> | null, shops?: Array<{ id: string } | null> | null };

export type AdAdvertisementImageFragment = { __typename: 'AdsAdvertisementImage', id: string, displayAt: AdDisplayAt, targetType: AdTargetType, weight: number, fileUrl?: string | null, displayUrl: string, categories?: Array<{ id: string } | null> | null, shops?: Array<{ id: string } | null> | null };

export type BrandFragment = { id: string, name: string, slug: string, fileUrl?: string | null, description?: string | null };

export type BrochureFragment = { activeFrom: any, expireAfter: any, id: string, name: string, publishedAt: any, hotspotMode: HotspotMode, regional: boolean, position: number, cover: { fileUrl?: string | null, id: string, index: number, imageRatio: number }, shop: { slug: string } };

export type BrochureOverviewFragment = { id: string, name: string, hotspotMode: HotspotMode, regional: boolean, shop: { name: string, fileUrl?: string | null } };

export type BrochureSearchResultFragment = { activeFrom: any, expireAfter: any, id: string, name: string, publishedAt: any, regional: boolean, cover: { fileUrl?: string | null, imageRatio: number }, shop: { slug: string } };

export type CategoryFragment = { about?: string | null, fileUrl?: string | null, id: string, name: string, slug: string };

export type CityFragment = { id: string, name: string, slug: string };

type Hotspot_HotspotImageEntity_Fragment = { __typename: 'HotspotImageEntity', fileUrl?: string | null, id: string, x: number, y: number, height: number, width: number, url?: string | null, shareUrl: string, pageId: string };

type Hotspot_HotspotLinkEntity_Fragment = { __typename: 'HotspotLinkEntity', fileUrl?: string | null, id: string, x: number, y: number, height: number, width: number, url?: string | null, shareUrl: string, pageId: string };

type Hotspot_HotspotProductEntity_Fragment = { __typename: 'HotspotProductEntity', fileUrl?: string | null, id: string, x: number, y: number, height: number, width: number, url?: string | null, shareUrl: string, pageId: string, productTag: { id: string, name: string, description?: string | null, discountPercent?: number | null, priceAfterDiscount?: number | null, priceBeforeDiscount?: number | null, brochureId: string, pageIndex: number, shop: { id: string, fileUrl?: string | null, slug: string, name: string }, hotspot: { id: string } | { id: string } | { fileUrl?: string | null, width: number, height: number, id: string } } };

export type HotspotFragment = Hotspot_HotspotImageEntity_Fragment | Hotspot_HotspotLinkEntity_Fragment | Hotspot_HotspotProductEntity_Fragment;

export type OfferCategoryFragment = { id: string, name: string, slug: string };

export type OfferFragment = { id: string, name: string, description?: string | null, discountPercent?: number | null, priceAfterDiscount?: number | null, priceBeforeDiscount?: number | null, brochureId: string, pageIndex: number, shop: { id: string, fileUrl?: string | null, slug: string, name: string }, hotspot: { id: string } | { id: string } | { fileUrl?: string | null, width: number, height: number, id: string } };

export type OfferSearchResultFragment = { id: string, name: string, description?: string | null, discountPercent?: number | null, priceAfterDiscount?: number | null, priceBeforeDiscount?: number | null, brochureId: string, pageIndex: number, shop: { id: string, fileUrl?: string | null, slug: string, name: string }, hotspot: { id: string } | { id: string } | { fileUrl?: string | null, id: string } };

export type PageFragment = { id: string, index: number, image_small?: string | null, image_large?: string | null };

export type SearchSuggestionFragment = { name: string, referenceId: string, type: SearchSuggestionType };

export type ShopDetailFragment = { id: string, name: string, fileUrl?: string | null, slug: string, description?: string | null, metaTitle?: string | null, metaDescription?: string | null };

export type ShopOverviewFragment = { id: string, name: string, fileUrl?: string | null, slug: string };

export type ShopSearchResultFragment = { id: string, name: string, fileUrl?: string | null, slug: string };

type WebsitePage_WebsitePagePage_Fragment = { shopContent: Array<string>, content?: string | null, metaDescription?: string | null, metaTitle?: string | null, path: string, title?: string | null, positionInFooter?: number | null, shopQuestionsAnswers: Array<{ answer: string, question: string }> };

type WebsitePage_WebsitePagePost_Fragment = { coverImage: string, publishedAt: any, content: string, metaDescription?: string | null, metaTitle?: string | null, path: string, title: string, positionInFooter?: number | null, categories: Array<{ about?: string | null, fileUrl?: string | null, id: string, name: string, slug: string }>, user?: { firstName: string, lastName: string, fileUrl?: string | null } | null };

export type WebsitePageFragment = WebsitePage_WebsitePagePage_Fragment | WebsitePage_WebsitePagePost_Fragment;

export type WebsitePostFragment = { content: string, coverImage: string, metaDescription?: string | null, metaTitle?: string | null, path: string, publishedAt: any, title: string };

export type WebsitePostOverviewFragment = { coverImage: string, path: string, publishedAt: any, title: string, categories: Array<{ name: string, id: string, slug: string }> };

export type CreateAdClickStatisticMutationVariables = Exact<{
  createAdClickStatistic: CreateAdClickStatisticInput;
}>;


export type CreateAdClickStatisticMutation = { createAdClickStatistic?: { status?: MutatedStatus | null } | null };

export type CreateAdImpressionStatisticMutationVariables = Exact<{
  createAdImpressionStatistic: CreateAdImpressionStatisticInput;
}>;


export type CreateAdImpressionStatisticMutation = { createAdImpressionStatistic?: { status?: MutatedStatus | null } | null };

export type CreateBrochureImpressionStatisticMutationVariables = Exact<{
  createBrochureImpressionStatistic: CreateBrochureImpressionStatisticInput;
}>;


export type CreateBrochureImpressionStatisticMutation = { createBrochureImpressionStatistic?: { status?: MutatedStatus | null } | null };

export type CreateBrochureVisitStatisticMutationVariables = Exact<{
  createBrochureVisitStatistic: CreateBrochureVisitStatisticInput;
}>;


export type CreateBrochureVisitStatisticMutation = { createBrochureVisitStatistic?: { status?: MutatedStatus | null } | null };

export type CreateDeviceMutationVariables = Exact<{
  createDevice: CreateDeviceInput;
}>;


export type CreateDeviceMutation = { createDevice: { status?: CreateDeviceStatus | null } };

export type CreateHotspotStatisticMutationVariables = Exact<{
  createHotspotStatistic: CreateHotspotStatisticInput;
}>;


export type CreateHotspotStatisticMutation = { createHotspotStatistic: { status?: MutatedStatus | null } };

export type CreatePageTimeStatisticMutationVariables = Exact<{
  createPageTimeStatistic: CreatePageTimeStatisticInput;
}>;


export type CreatePageTimeStatisticMutation = { createPageTimeStatistic?: { status?: MutatedStatus | null } | null };

export type CreatePageVisitStatisticMutationVariables = Exact<{
  createPageVisitStatistic: CreatePageVisitStatisticInput;
}>;


export type CreatePageVisitStatisticMutation = { createPageVisitStatistic?: { status?: MutatedStatus | null } | null };

export type AdsAdvertisementsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdsAdvertisementsQuery = { adsAdvertisements: Array<{ __typename: 'AdsAdvertisementHtmlEmbed', id: string, displayAt: AdDisplayAt, targetType: AdTargetType, weight: number, content: string, categories?: Array<{ id: string } | null> | null, shops?: Array<{ id: string } | null> | null } | { __typename: 'AdsAdvertisementImage', id: string, displayAt: AdDisplayAt, targetType: AdTargetType, weight: number, fileUrl?: string | null, displayUrl: string, categories?: Array<{ id: string } | null> | null, shops?: Array<{ id: string } | null> | null }> };

export type BrandQueryVariables = Exact<{
  brandInput: BrandInput;
}>;


export type BrandQuery = { brand?: { id: string, name: string, slug: string, fileUrl?: string | null, description?: string | null } | null };

export type BrandsQueryVariables = Exact<{
  brandsInput: BrandsInput;
  pagination: PaginationInput;
  limitPlusOffset: Scalars['Int']['input'];
}>;


export type BrandsQuery = { brands: Array<{ id: string, name: string, slug: string, fileUrl?: string | null, description?: string | null }>, nextBrand: Array<{ id: string }> };

export type BrochureQueryVariables = Exact<{
  brochureInput: BrochureInput;
}>;


export type BrochureQuery = { brochure?: { activeFrom: any, expireAfter: any, id: string, name: string, publishedAt: any, hotspotMode: HotspotMode, regional: boolean, position: number, cover: { fileUrl?: string | null, id: string, index: number, imageRatio: number }, shop: { slug: string } } | null };

export type BrochureOverviewQueryVariables = Exact<{
  brochureInput: BrochureInput;
}>;


export type BrochureOverviewQuery = { brochure?: { id: string, name: string, hotspotMode: HotspotMode, regional: boolean, shop: { name: string, fileUrl?: string | null } } | null };

export type BrochuresQueryVariables = Exact<{
  brochuresInput: BrochuresInput;
  pagination: PaginationInput;
  limitPlusOffset: Scalars['Int']['input'];
}>;


export type BrochuresQuery = { brochures: Array<{ activeFrom: any, expireAfter: any, id: string, name: string, publishedAt: any, hotspotMode: HotspotMode, regional: boolean, position: number, cover: { fileUrl?: string | null, id: string, index: number, imageRatio: number }, shop: { slug: string } }>, nextBrochure: Array<{ id: string }> };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { categories: Array<{ about?: string | null, fileUrl?: string | null, id: string, name: string, slug: string }> };

export type CategoryQueryVariables = Exact<{
  categoryInput: CategoryInput;
}>;


export type CategoryQuery = { category?: { about?: string | null, fileUrl?: string | null, id: string, name: string, slug: string } | null };

export type CitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CitiesQuery = { cities: Array<{ id: string, name: string, slug: string }> };

export type CityQueryVariables = Exact<{
  cityInput: CityInput;
}>;


export type CityQuery = { city?: { id: string, name: string, slug: string } | null };

export type HotspotsByPageQueryVariables = Exact<{
  hotspotsInput: HotspotsByPageInput;
}>;


export type HotspotsByPageQuery = { hotspotsByPage: Array<{ __typename: 'HotspotImageEntity', fileUrl?: string | null, id: string, x: number, y: number, height: number, width: number, url?: string | null, shareUrl: string, pageId: string } | { __typename: 'HotspotLinkEntity', fileUrl?: string | null, id: string, x: number, y: number, height: number, width: number, url?: string | null, shareUrl: string, pageId: string } | { __typename: 'HotspotProductEntity', fileUrl?: string | null, id: string, x: number, y: number, height: number, width: number, url?: string | null, shareUrl: string, pageId: string, productTag: { id: string, name: string, description?: string | null, discountPercent?: number | null, priceAfterDiscount?: number | null, priceBeforeDiscount?: number | null, brochureId: string, pageIndex: number, shop: { id: string, fileUrl?: string | null, slug: string, name: string }, hotspot: { id: string } | { id: string } | { fileUrl?: string | null, width: number, height: number, id: string } } }> };

export type OfferCategoryQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type OfferCategoryQuery = { offerCategories: Array<{ id: string, name: string, slug: string }> };

export type OffersQueryVariables = Exact<{
  offersInput: OffersInput;
  pagination: PaginationInput;
}>;


export type OffersQuery = { offers: Array<{ id: string, name: string, description?: string | null, discountPercent?: number | null, priceAfterDiscount?: number | null, priceBeforeDiscount?: number | null, brochureId: string, pageIndex: number, shop: { id: string, fileUrl?: string | null, slug: string, name: string }, hotspot: { id: string } | { id: string } | { fileUrl?: string | null, width: number, height: number, id: string } }> };

export type PagesQueryVariables = Exact<{
  pagesInput: PagesInput;
}>;


export type PagesQuery = { pages: Array<{ id: string, index: number, image_small?: string | null, image_large?: string | null }> };

export type SearchResultQueryVariables = Exact<{
  searchResults: SearchResultsInput;
  pagination: PaginationInput;
}>;


export type SearchResultQuery = { searchResults: { __typename: 'SearchBrochureList', searchMethod: SearchMethod, brochures: Array<{ activeFrom: any, expireAfter: any, id: string, name: string, publishedAt: any, regional: boolean, cover: { fileUrl?: string | null, imageRatio: number }, shop: { slug: string } }> } | { __typename: 'SearchNoResults', status: SearchNoResultsStatus } | { __typename: 'SearchOfferList', searchMethod: SearchMethod, offers: Array<{ id: string, name: string, description?: string | null, discountPercent?: number | null, priceAfterDiscount?: number | null, priceBeforeDiscount?: number | null, brochureId: string, pageIndex: number, shop: { id: string, fileUrl?: string | null, slug: string, name: string }, hotspot: { id: string } | { id: string } | { fileUrl?: string | null, id: string } }> } | { __typename: 'SearchShopList', searchMethod: SearchMethod, shops: Array<{ id: string, name: string, fileUrl?: string | null, slug: string }> } };

export type SearchSuggestionsQueryVariables = Exact<{
  searchSuggestions: SearchSuggestionsInput;
}>;


export type SearchSuggestionsQuery = { searchSuggestions: Array<{ name: string, referenceId: string, type: SearchSuggestionType }> };

export type ShopQueryVariables = Exact<{
  shopInput: ShopInput;
}>;


export type ShopQuery = { shop?: { id: string, name: string, fileUrl?: string | null, slug: string, description?: string | null, metaTitle?: string | null, metaDescription?: string | null } | null };

export type ShopsQueryVariables = Exact<{
  shopsInput: ShopsInput;
  pagination: PaginationInput;
  limitPlusOffset: Scalars['Int']['input'];
}>;


export type ShopsQuery = { shops: Array<{ id: string, name: string, fileUrl?: string | null, slug: string }>, nextShop: Array<{ id: string }> };

export type SimilarBrochuresForBrochureQueryVariables = Exact<{
  brochuresInput: SimilarBrochuresForBrochureInput;
}>;


export type SimilarBrochuresForBrochureQuery = { similarBrochuresForBrochure: Array<{ activeFrom: any, expireAfter: any, id: string, name: string, publishedAt: any, hotspotMode: HotspotMode, regional: boolean, position: number, cover: { fileUrl?: string | null, id: string, index: number, imageRatio: number }, shop: { slug: string } }> };

export type SimilarBrochuresForShopQueryVariables = Exact<{
  brochuresInput: SimilarBrochuresForShopInput;
}>;


export type SimilarBrochuresForShopQuery = { similarBrochuresForShop: Array<{ activeFrom: any, expireAfter: any, id: string, name: string, publishedAt: any, hotspotMode: HotspotMode, regional: boolean, position: number, cover: { fileUrl?: string | null, id: string, index: number, imageRatio: number }, shop: { slug: string } }> };

export type SimilarShopsForShopQueryVariables = Exact<{
  shopInput: SimilarShopsForShopInput;
  pagination: PaginationInput;
}>;


export type SimilarShopsForShopQuery = { similarShopsForShop: Array<{ id: string, name: string, fileUrl?: string | null, slug: string }> };

export type WebsitePageQueryVariables = Exact<{
  websitePage: WebsitePageInput;
}>;


export type WebsitePageQuery = { websitePage?: { shopContent: Array<string>, content?: string | null, metaDescription?: string | null, metaTitle?: string | null, path: string, title?: string | null, positionInFooter?: number | null, shopQuestionsAnswers: Array<{ answer: string, question: string }> } | { coverImage: string, publishedAt: any, content: string, metaDescription?: string | null, metaTitle?: string | null, path: string, title: string, positionInFooter?: number | null, categories: Array<{ about?: string | null, fileUrl?: string | null, id: string, name: string, slug: string }>, user?: { firstName: string, lastName: string, fileUrl?: string | null } | null } | null };

export type WebsitePagesQueryVariables = Exact<{ [key: string]: never; }>;


export type WebsitePagesQuery = { websitePages: Array<{ shopContent: Array<string>, content?: string | null, metaDescription?: string | null, metaTitle?: string | null, path: string, title?: string | null, positionInFooter?: number | null, shopQuestionsAnswers: Array<{ answer: string, question: string }> }> };

export type WebsitePostsQueryVariables = Exact<{
  websitePosts: WebsitePostsInput;
  pagination: PaginationInput;
}>;


export type WebsitePostsQuery = { websitePosts: Array<{ coverImage: string, path: string, publishedAt: any, title: string, categories: Array<{ name: string, id: string, slug: string }> }> };

export const AdAdvertisementHtmlEmbedFragmentDoc = gql`
    fragment adAdvertisementHtmlEmbed on AdsAdvertisementHtmlEmbed {
  __typename
  id
  displayAt
  targetType
  categories {
    id
  }
  shops {
    id
  }
  weight
  content
}
    `;
export const AdAdvertisementImageFragmentDoc = gql`
    fragment adAdvertisementImage on AdsAdvertisementImage {
  __typename
  id
  displayAt
  targetType
  categories {
    id
  }
  shops {
    id
  }
  weight
  fileUrl
  displayUrl
}
    `;
export const AdAdvertisementFragmentDoc = gql`
    fragment adAdvertisement on AdsAdvertisementEntity {
  ... on AdsAdvertisementHtmlEmbed {
    ...adAdvertisementHtmlEmbed
  }
  ... on AdsAdvertisementImage {
    ...adAdvertisementImage
  }
}
    ${AdAdvertisementHtmlEmbedFragmentDoc}
${AdAdvertisementImageFragmentDoc}`;
export const BrandFragmentDoc = gql`
    fragment brand on BrandEntity {
  id
  name
  slug
  fileUrl(version: SMALL)
  description
}
    `;
export const BrochureFragmentDoc = gql`
    fragment brochure on BrochureEntity {
  activeFrom
  expireAfter
  cover {
    fileUrl(version: SMALL)
    id
    index
    imageRatio
  }
  id
  name
  publishedAt
  shop {
    slug
  }
  hotspotMode
  regional
  position
}
    `;
export const BrochureOverviewFragmentDoc = gql`
    fragment brochureOverview on BrochureEntity {
  id
  name
  hotspotMode
  regional
  shop {
    name
    fileUrl(version: SMALL)
  }
}
    `;
export const BrochureSearchResultFragmentDoc = gql`
    fragment brochureSearchResult on BrochureSearchEntity {
  activeFrom
  expireAfter
  cover {
    fileUrl(version: SMALL)
    imageRatio
  }
  id
  name
  publishedAt
  regional
  shop {
    slug
  }
}
    `;
export const CityFragmentDoc = gql`
    fragment city on CityEntity {
  id
  name
  slug
}
    `;
export const OfferFragmentDoc = gql`
    fragment offer on OfferEntity {
  id
  name
  description
  discountPercent
  priceAfterDiscount
  priceBeforeDiscount
  brochureId
  pageIndex
  shop {
    id
    fileUrl(version: SMALL)
    slug
    name
  }
  hotspot {
    id
    ... on HotspotProductEntity {
      fileUrl(version: SMALL)
      width
      height
    }
  }
}
    `;
export const HotspotFragmentDoc = gql`
    fragment hotspot on HotspotEntity {
  id
  x
  y
  height
  width
  url
  shareUrl
  pageId
  ... on HotspotProductEntity {
    __typename
    fileUrl(version: SMALL)
    productTag {
      ...offer
    }
  }
  ... on HotspotLinkEntity {
    fileUrl(version: SMALL)
    __typename
  }
  ... on HotspotImageEntity {
    __typename
    fileUrl(version: SMALL)
  }
}
    ${OfferFragmentDoc}`;
export const OfferCategoryFragmentDoc = gql`
    fragment offerCategory on OfferCategoryEntity {
  id
  name
  slug
}
    `;
export const OfferSearchResultFragmentDoc = gql`
    fragment offerSearchResult on OfferSearchEntity {
  id
  name
  description
  discountPercent
  priceAfterDiscount
  priceBeforeDiscount
  brochureId
  pageIndex
  shop {
    id
    fileUrl(version: SMALL)
    slug
    name
  }
  hotspot {
    id
    ... on HotspotProductEntity {
      fileUrl(version: SMALL)
    }
  }
}
    `;
export const PageFragmentDoc = gql`
    fragment page on PageEntity {
  id
  index
  image_small: fileUrl(version: SMALL)
  image_large: fileUrl(version: LARGE)
}
    `;
export const SearchSuggestionFragmentDoc = gql`
    fragment searchSuggestion on SearchSuggestionEntity {
  name
  referenceId
  type
}
    `;
export const ShopDetailFragmentDoc = gql`
    fragment shopDetail on ShopEntity {
  id
  name
  fileUrl(version: SMALL)
  slug
  description
  metaTitle
  metaDescription
}
    `;
export const ShopOverviewFragmentDoc = gql`
    fragment shopOverview on ShopEntity {
  id
  name
  fileUrl(version: SMALL)
  slug
}
    `;
export const ShopSearchResultFragmentDoc = gql`
    fragment shopSearchResult on ShopEntity {
  id
  name
  fileUrl(version: SMALL)
  slug
}
    `;
export const CategoryFragmentDoc = gql`
    fragment category on CategoryEntity {
  about
  fileUrl(version: SMALL)
  id
  name
  slug
}
    `;
export const WebsitePageFragmentDoc = gql`
    fragment websitePage on WebsitePageEntity {
  content
  metaDescription
  metaTitle
  path
  title
  positionInFooter
  ... on WebsitePagePage {
    shopContent
    shopQuestionsAnswers {
      answer
      question
    }
  }
  ... on WebsitePagePost {
    coverImage(version: MEDIUM)
    publishedAt
    categories {
      ...category
    }
    user {
      firstName
      lastName
      fileUrl(version: SMALL)
    }
  }
}
    ${CategoryFragmentDoc}`;
export const WebsitePostFragmentDoc = gql`
    fragment websitePost on WebsitePagePost {
  content
  coverImage(version: MEDIUM)
  metaDescription
  metaTitle
  path
  publishedAt
  title
}
    `;
export const WebsitePostOverviewFragmentDoc = gql`
    fragment websitePostOverview on WebsitePagePost {
  coverImage(version: MEDIUM)
  path
  publishedAt
  title
  categories {
    name
    id
    slug
  }
}
    `;
export const CreateAdClickStatisticDocument = gql`
    mutation createAdClickStatistic($createAdClickStatistic: CreateAdClickStatisticInput!) {
  createAdClickStatistic(createAdClickStatistic: $createAdClickStatistic) {
    status
  }
}
    `;

export function useCreateAdClickStatisticMutation() {
  return Urql.useMutation<CreateAdClickStatisticMutation, CreateAdClickStatisticMutationVariables>(CreateAdClickStatisticDocument);
};
export const CreateAdImpressionStatisticDocument = gql`
    mutation createAdImpressionStatistic($createAdImpressionStatistic: CreateAdImpressionStatisticInput!) {
  createAdImpressionStatistic(
    createAdImpressionStatistic: $createAdImpressionStatistic
  ) {
    status
  }
}
    `;

export function useCreateAdImpressionStatisticMutation() {
  return Urql.useMutation<CreateAdImpressionStatisticMutation, CreateAdImpressionStatisticMutationVariables>(CreateAdImpressionStatisticDocument);
};
export const CreateBrochureImpressionStatisticDocument = gql`
    mutation createBrochureImpressionStatistic($createBrochureImpressionStatistic: CreateBrochureImpressionStatisticInput!) {
  createBrochureImpressionStatistic(
    createBrochureImpressionStatistic: $createBrochureImpressionStatistic
  ) {
    status
  }
}
    `;

export function useCreateBrochureImpressionStatisticMutation() {
  return Urql.useMutation<CreateBrochureImpressionStatisticMutation, CreateBrochureImpressionStatisticMutationVariables>(CreateBrochureImpressionStatisticDocument);
};
export const CreateBrochureVisitStatisticDocument = gql`
    mutation createBrochureVisitStatistic($createBrochureVisitStatistic: CreateBrochureVisitStatisticInput!) {
  createBrochureVisitStatistic(
    createBrochureVisitStatistic: $createBrochureVisitStatistic
  ) {
    status
  }
}
    `;

export function useCreateBrochureVisitStatisticMutation() {
  return Urql.useMutation<CreateBrochureVisitStatisticMutation, CreateBrochureVisitStatisticMutationVariables>(CreateBrochureVisitStatisticDocument);
};
export const CreateDeviceDocument = gql`
    mutation createDevice($createDevice: CreateDeviceInput!) {
  createDevice(createDevice: $createDevice) {
    status
  }
}
    `;

export function useCreateDeviceMutation() {
  return Urql.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument);
};
export const CreateHotspotStatisticDocument = gql`
    mutation createHotspotStatistic($createHotspotStatistic: CreateHotspotStatisticInput!) {
  createHotspotStatistic(createHotspotStatistic: $createHotspotStatistic) {
    status
  }
}
    `;

export function useCreateHotspotStatisticMutation() {
  return Urql.useMutation<CreateHotspotStatisticMutation, CreateHotspotStatisticMutationVariables>(CreateHotspotStatisticDocument);
};
export const CreatePageTimeStatisticDocument = gql`
    mutation createPageTimeStatistic($createPageTimeStatistic: CreatePageTimeStatisticInput!) {
  createPageTimeStatistic(createPageTimeStatistic: $createPageTimeStatistic) {
    status
  }
}
    `;

export function useCreatePageTimeStatisticMutation() {
  return Urql.useMutation<CreatePageTimeStatisticMutation, CreatePageTimeStatisticMutationVariables>(CreatePageTimeStatisticDocument);
};
export const CreatePageVisitStatisticDocument = gql`
    mutation createPageVisitStatistic($createPageVisitStatistic: CreatePageVisitStatisticInput!) {
  createPageVisitStatistic(createPageVisitStatistic: $createPageVisitStatistic) {
    status
  }
}
    `;

export function useCreatePageVisitStatisticMutation() {
  return Urql.useMutation<CreatePageVisitStatisticMutation, CreatePageVisitStatisticMutationVariables>(CreatePageVisitStatisticDocument);
};
export const AdsAdvertisementsDocument = gql`
    query AdsAdvertisements {
  adsAdvertisements {
    ...adAdvertisement
  }
}
    ${AdAdvertisementFragmentDoc}`;

export function useAdsAdvertisementsQuery(options?: Omit<Urql.UseQueryArgs<never, AdsAdvertisementsQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<AdsAdvertisementsQuery, AdsAdvertisementsQueryVariables | undefined>({ query: AdsAdvertisementsDocument, variables: undefined, ...options });
};
export const BrandDocument = gql`
    query Brand($brandInput: BrandInput!) {
  brand(brand: $brandInput) {
    ...brand
  }
}
    ${BrandFragmentDoc}`;

export function useBrandQuery(options?: Omit<Urql.UseQueryArgs<never, BrandQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<BrandQuery, BrandQueryVariables | undefined>({ query: BrandDocument, variables: undefined, ...options });
};
export const BrandsDocument = gql`
    query Brands($brandsInput: BrandsInput!, $pagination: PaginationInput!, $limitPlusOffset: Int!) {
  brands(brands: $brandsInput, pagination: $pagination) {
    ...brand
  }
  nextBrand: brands(
    brands: $brandsInput
    pagination: {limit: 1, offset: $limitPlusOffset}
  ) {
    id
  }
}
    ${BrandFragmentDoc}`;

export function useBrandsQuery(options?: Omit<Urql.UseQueryArgs<never, BrandsQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<BrandsQuery, BrandsQueryVariables | undefined>({ query: BrandsDocument, variables: undefined, ...options });
};
export const BrochureDocument = gql`
    query Brochure($brochureInput: BrochureInput!) {
  brochure(brochure: $brochureInput) {
    ...brochure
  }
}
    ${BrochureFragmentDoc}`;

export function useBrochureQuery(options?: Omit<Urql.UseQueryArgs<never, BrochureQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<BrochureQuery, BrochureQueryVariables | undefined>({ query: BrochureDocument, variables: undefined, ...options });
};
export const BrochureOverviewDocument = gql`
    query BrochureOverview($brochureInput: BrochureInput!) {
  brochure(brochure: $brochureInput) {
    ...brochureOverview
  }
}
    ${BrochureOverviewFragmentDoc}`;

export function useBrochureOverviewQuery(options?: Omit<Urql.UseQueryArgs<never, BrochureOverviewQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<BrochureOverviewQuery, BrochureOverviewQueryVariables | undefined>({ query: BrochureOverviewDocument, variables: undefined, ...options });
};
export const BrochuresDocument = gql`
    query Brochures($brochuresInput: BrochuresInput!, $pagination: PaginationInput!, $limitPlusOffset: Int!) {
  brochures(brochures: $brochuresInput, pagination: $pagination) {
    ...brochure
  }
  nextBrochure: brochures(
    brochures: $brochuresInput
    pagination: {limit: 1, offset: $limitPlusOffset}
  ) {
    id
  }
}
    ${BrochureFragmentDoc}`;

export function useBrochuresQuery(options?: Omit<Urql.UseQueryArgs<never, BrochuresQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<BrochuresQuery, BrochuresQueryVariables | undefined>({ query: BrochuresDocument, variables: undefined, ...options });
};
export const CategoriesDocument = gql`
    query Categories {
  categories {
    ...category
  }
}
    ${CategoryFragmentDoc}`;

export function useCategoriesQuery(options?: Omit<Urql.UseQueryArgs<never, CategoriesQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<CategoriesQuery, CategoriesQueryVariables | undefined>({ query: CategoriesDocument, variables: undefined, ...options });
};
export const CategoryDocument = gql`
    query Category($categoryInput: CategoryInput!) {
  category(category: $categoryInput) {
    ...category
  }
}
    ${CategoryFragmentDoc}`;

export function useCategoryQuery(options?: Omit<Urql.UseQueryArgs<never, CategoryQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<CategoryQuery, CategoryQueryVariables | undefined>({ query: CategoryDocument, variables: undefined, ...options });
};
export const CitiesDocument = gql`
    query Cities {
  cities {
    ...city
  }
}
    ${CityFragmentDoc}`;

export function useCitiesQuery(options?: Omit<Urql.UseQueryArgs<never, CitiesQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<CitiesQuery, CitiesQueryVariables | undefined>({ query: CitiesDocument, variables: undefined, ...options });
};
export const CityDocument = gql`
    query City($cityInput: CityInput!) {
  city(city: $cityInput) {
    ...city
  }
}
    ${CityFragmentDoc}`;

export function useCityQuery(options?: Omit<Urql.UseQueryArgs<never, CityQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<CityQuery, CityQueryVariables | undefined>({ query: CityDocument, variables: undefined, ...options });
};
export const HotspotsByPageDocument = gql`
    query HotspotsByPage($hotspotsInput: HotspotsByPageInput!) {
  hotspotsByPage(hotspotsByPage: $hotspotsInput) {
    ...hotspot
  }
}
    ${HotspotFragmentDoc}`;

export function useHotspotsByPageQuery(options?: Omit<Urql.UseQueryArgs<never, HotspotsByPageQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<HotspotsByPageQuery, HotspotsByPageQueryVariables | undefined>({ query: HotspotsByPageDocument, variables: undefined, ...options });
};
export const OfferCategoryDocument = gql`
    query OfferCategory($pagination: PaginationInput!) {
  offerCategories(pagination: $pagination) {
    ...offerCategory
  }
}
    ${OfferCategoryFragmentDoc}`;

export function useOfferCategoryQuery(options?: Omit<Urql.UseQueryArgs<never, OfferCategoryQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<OfferCategoryQuery, OfferCategoryQueryVariables | undefined>({ query: OfferCategoryDocument, variables: undefined, ...options });
};
export const OffersDocument = gql`
    query Offers($offersInput: OffersInput!, $pagination: PaginationInput!) {
  offers(offers: $offersInput, pagination: $pagination) {
    ...offer
  }
}
    ${OfferFragmentDoc}`;

export function useOffersQuery(options?: Omit<Urql.UseQueryArgs<never, OffersQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<OffersQuery, OffersQueryVariables | undefined>({ query: OffersDocument, variables: undefined, ...options });
};
export const PagesDocument = gql`
    query Pages($pagesInput: PagesInput!) {
  pages(pages: $pagesInput) {
    ...page
  }
}
    ${PageFragmentDoc}`;

export function usePagesQuery(options?: Omit<Urql.UseQueryArgs<never, PagesQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<PagesQuery, PagesQueryVariables | undefined>({ query: PagesDocument, variables: undefined, ...options });
};
export const SearchResultDocument = gql`
    query SearchResult($searchResults: SearchResultsInput!, $pagination: PaginationInput!) {
  searchResults(searchResults: $searchResults, pagination: $pagination) {
    __typename
    ... on SearchBrochureList {
      brochures {
        ...brochureSearchResult
      }
      searchMethod
    }
    ... on SearchOfferList {
      offers {
        ...offerSearchResult
      }
      searchMethod
    }
    ... on SearchShopList {
      shops {
        ...shopSearchResult
      }
      searchMethod
    }
    ... on SearchNoResults {
      status
    }
  }
}
    ${BrochureSearchResultFragmentDoc}
${OfferSearchResultFragmentDoc}
${ShopSearchResultFragmentDoc}`;

export function useSearchResultQuery(options?: Omit<Urql.UseQueryArgs<never, SearchResultQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<SearchResultQuery, SearchResultQueryVariables | undefined>({ query: SearchResultDocument, variables: undefined, ...options });
};
export const SearchSuggestionsDocument = gql`
    query SearchSuggestions($searchSuggestions: SearchSuggestionsInput!) {
  searchSuggestions(searchSuggestions: $searchSuggestions) {
    ...searchSuggestion
  }
}
    ${SearchSuggestionFragmentDoc}`;

export function useSearchSuggestionsQuery(options?: Omit<Urql.UseQueryArgs<never, SearchSuggestionsQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<SearchSuggestionsQuery, SearchSuggestionsQueryVariables | undefined>({ query: SearchSuggestionsDocument, variables: undefined, ...options });
};
export const ShopDocument = gql`
    query Shop($shopInput: ShopInput!) {
  shop(shop: $shopInput) {
    ...shopDetail
  }
}
    ${ShopDetailFragmentDoc}`;

export function useShopQuery(options?: Omit<Urql.UseQueryArgs<never, ShopQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<ShopQuery, ShopQueryVariables | undefined>({ query: ShopDocument, variables: undefined, ...options });
};
export const ShopsDocument = gql`
    query Shops($shopsInput: ShopsInput!, $pagination: PaginationInput!, $limitPlusOffset: Int!) {
  shops(shops: $shopsInput, pagination: $pagination) {
    ...shopOverview
  }
  nextShop: shops(
    shops: $shopsInput
    pagination: {limit: 1, offset: $limitPlusOffset}
  ) {
    id
  }
}
    ${ShopOverviewFragmentDoc}`;

export function useShopsQuery(options?: Omit<Urql.UseQueryArgs<never, ShopsQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<ShopsQuery, ShopsQueryVariables | undefined>({ query: ShopsDocument, variables: undefined, ...options });
};
export const SimilarBrochuresForBrochureDocument = gql`
    query SimilarBrochuresForBrochure($brochuresInput: SimilarBrochuresForBrochureInput!) {
  similarBrochuresForBrochure(similarBrochuresForBrochure: $brochuresInput) {
    ...brochure
  }
}
    ${BrochureFragmentDoc}`;

export function useSimilarBrochuresForBrochureQuery(options?: Omit<Urql.UseQueryArgs<never, SimilarBrochuresForBrochureQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<SimilarBrochuresForBrochureQuery, SimilarBrochuresForBrochureQueryVariables | undefined>({ query: SimilarBrochuresForBrochureDocument, variables: undefined, ...options });
};
export const SimilarBrochuresForShopDocument = gql`
    query SimilarBrochuresForShop($brochuresInput: SimilarBrochuresForShopInput!) {
  similarBrochuresForShop(similarBrochuresForShop: $brochuresInput) {
    ...brochure
  }
}
    ${BrochureFragmentDoc}`;

export function useSimilarBrochuresForShopQuery(options?: Omit<Urql.UseQueryArgs<never, SimilarBrochuresForShopQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<SimilarBrochuresForShopQuery, SimilarBrochuresForShopQueryVariables | undefined>({ query: SimilarBrochuresForShopDocument, variables: undefined, ...options });
};
export const SimilarShopsForShopDocument = gql`
    query SimilarShopsForShop($shopInput: SimilarShopsForShopInput!, $pagination: PaginationInput!) {
  similarShopsForShop(similarShopsForShop: $shopInput, pagination: $pagination) {
    ...shopOverview
  }
}
    ${ShopOverviewFragmentDoc}`;

export function useSimilarShopsForShopQuery(options?: Omit<Urql.UseQueryArgs<never, SimilarShopsForShopQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<SimilarShopsForShopQuery, SimilarShopsForShopQueryVariables | undefined>({ query: SimilarShopsForShopDocument, variables: undefined, ...options });
};
export const WebsitePageDocument = gql`
    query WebsitePage($websitePage: WebsitePageInput!) {
  websitePage(websitePage: $websitePage) {
    ...websitePage
  }
}
    ${WebsitePageFragmentDoc}`;

export function useWebsitePageQuery(options?: Omit<Urql.UseQueryArgs<never, WebsitePageQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<WebsitePageQuery, WebsitePageQueryVariables | undefined>({ query: WebsitePageDocument, variables: undefined, ...options });
};
export const WebsitePagesDocument = gql`
    query WebsitePages {
  websitePages {
    ...websitePage
  }
}
    ${WebsitePageFragmentDoc}`;

export function useWebsitePagesQuery(options?: Omit<Urql.UseQueryArgs<never, WebsitePagesQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<WebsitePagesQuery, WebsitePagesQueryVariables | undefined>({ query: WebsitePagesDocument, variables: undefined, ...options });
};
export const WebsitePostsDocument = gql`
    query WebsitePosts($websitePosts: WebsitePostsInput!, $pagination: PaginationInput!) {
  websitePosts(websitePosts: $websitePosts, pagination: $pagination) {
    ...websitePostOverview
  }
}
    ${WebsitePostOverviewFragmentDoc}`;

export function useWebsitePostsQuery(options?: Omit<Urql.UseQueryArgs<never, WebsitePostsQueryVariables | undefined>, 'query'>) {
  return Urql.useQuery<WebsitePostsQuery, WebsitePostsQueryVariables | undefined>({ query: WebsitePostsDocument, variables: undefined, ...options });
};